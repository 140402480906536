<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogCloseAssist" />
  </div>
</template>
<script>
import supportService from "../../services/support.service";

export default {
  name: "CloseAssist",
  props: ["show", "showToast", "patId", "patName"],
  watch: {
    show() {
      if (this.show === true) {
        this.confirmCloseAssist();
      }
    },
  },
  methods: {
    confirmCloseAssist() {
      let title = `Fechar Assistência`;
      let message = `Tem a certeza que pretende fechar a assistência do PAT ${this.patId} - ${this.patName}?`;

      this.$confirm.require({
        key: "dialogCloseAssist",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.closeAssist();
        },
        reject: () => {
          this.patId = null;
          return this.$emit("cancel");
        },
      });
    },
    closeAssist() {
      return supportService.closeAssist(this.patId).then((response) => {
        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao fechar a Assistência`,
              detail: "",
              life: 3000,
            });
          }
          return this.$emit("closeAssistOk", {
            status: "error",
            currentStatus: {},
          });
        }
        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: `Assistência Fechada`,
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("closeAssistOk", {
          status: "ok",
          currentStatus: response.status,
        });
      });
    },
  },
};
</script>
